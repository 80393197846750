import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import ReturnButton from '../components/ReturnButton';
import RecommendedPosts from '../components/RecommendedPosts';

import * as S from '../components/Post/styled';
import { Container } from '../styles';
import { Comments, ShareBlock } from '../components/Commons';

const BlogPost = ({ data, pageContext }) => {
	const post = data.markdownRemark;
    const imageUrl = `../${data.markdownRemark.frontmatter.image}`;
	const next = pageContext.nextPost;
    const previous = pageContext.previousPost;
    const url = `https://www.codeiscool.com.br${post.fields.slug}`;

	return (
		<Layout>
			<SEO title={post.frontmatter.title} />
            <Container>
                <S.PostHeader>
                    <ReturnButton />
                    <S.PostDate>{post.frontmatter.date} - {post.timeToRead} min de leitura</S.PostDate>
                    <S.PostTitle>{post.frontmatter.title}</S.PostTitle>
                </S.PostHeader>

                {/* <S.PostImageWrapper>
                    <GatsbyImage image={post.frontmatter.image} src={imageUrl} alt={post.frontmatter.title} />
                </S.PostImageWrapper> */}


                <S.Content>
                    <S.MainContent>
                        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
                    </S.MainContent>

                    <S.SideContent>
                        <ShareBlock
                            url={`https://www.codeiscool.com.br/${post.fields.slug}`}
                            title={post.frontmatter.title}
                            description={post.frontmatter.description}
                        />
                    </S.SideContent>
                </S.Content>

                <RecommendedPosts next={next} previous={previous} />
                <Comments url={post.fields.slug} title={post.frontmatter.title} />
            </Container>
		</Layout>
	)
}

export const query = graphql`
	query GetPost($slug: String!) {
		markdownRemark(fields: {slug: {eq: $slug}}) {
			fields {
				slug
			},
			frontmatter {
				title,
				description,
				date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
                image
			},
			html,
			timeToRead
		}
	}
`;

export default BlogPost;
