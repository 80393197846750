import React from 'react';
import { ArrowLeft } from 'react-feather';

import * as S from './styled';

const ReturnButton = () => (
	<S.ReturnWrapper to={'/'} title="Voltar para Home" cover diretion="left" bg="@16202c" duration={0.6}>
        <ArrowLeft />
		<S.Text>Voltar</S.Text>
	</S.ReturnWrapper>
);

export default ReturnButton;
