import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'gatsby'

export const RecommendedWrapper = styled.section`
	display: flex;
    justify-content: space-between;

	${media.greaterThan('large')`
		padding: 2rem 0;
		line-height: 1.3;
		font-size: 0.9rem
	`}
`

export const RecommendedLink = styled(Link)`
	align-items: center;
	color: var(--background-font);
	display: flex;
	padding: 0.5rem 0;
	text-decoration: none;
	transition: 0.5s;
	width: 50%;
	&:hover {
		background: var(--background-auxiliar);
        border-radius: 0.5rem;
	}
	&.previous {
		padding-right: 0.5rem;
	}
	&.next {
		justify-content: flex-end;
		padding-left: 0.5rem;
	}
	&.previous:before,
	&.next:after {
		font-weight: 900;
		background: var(--background-auxiliar);
		color: var(--background-font);
		display: flex;
		font-size: 0.75rem;
		min-width: 20px;
		height: 20px;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}

	&.previous:before {
		content: "\\2190";
		margin-right: 0.5rem;

	}
	&.next:after {
		content: "\\2192";
		margin-left: 0.5rem;
	}

	${media.greaterThan('large')`
		padding: 2rem;

		&.previous:before,
		&.next:after {
			font-size: 1.25rem;
			min-width: 40px;
			height: 40px;
		}
	`}
`
