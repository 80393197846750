import styled from 'styled-components';
import media from 'styled-media-query';

export const PostHeader = styled.header`
    margin: auto;
	padding: 3rem 0;
    color: var(--background-font);

	${media.lessThan('medium')`
		max-width: 100%;
		padding: 0;
	`}
`

export const PostTitle = styled.h1`
	font-size: 4rem;
	font-weight: 700;
	margin: 1rem auto;

	${media.lessThan('medium')`
		font-size: 2.8rem;
		line-height: 1.1;
	`}
`

export const PostDescription = styled.h2`
	font-size: 2rem;
	font-weight: 200;

	${media.lessThan('medium')`
		font-size: 1.6rem;
		line-height: 1.3;
	`}
`

export const PostDate = styled.p`
	font-size: 1.1rem;
	font-weight: 100;
`

export const PostImageWrapper = styled.div`
    .gatsby-image-wrapper {
        height: 20rem;
        margin: 0 6.5rem;
    }

    ${media.lessThan('medium')`
        .gatsby-image-wrapper {
            height: 15rem;
            max-width: 100%;
            margin: 0;
        }
	`}
`

export const Content = styled.div`
    ${media.greaterThan('medium')`
        display: flex;
    `}
`;

export const MainContent = styled.section`
	margin: auto;
	padding: 0 0 2rem;

	${media.lessThan('medium')`
		max-width: 100%;
        flex-grow: 1;
	`}

	p,
	h1,
	h2,
	h3,
	h4,
	ul,
	ol,
	.tags,
	iframe,
	.button-post {
		font-size: 1.25rem;
		font-weight: 300;
		line-height: 1.7;
		letter-spacing: 0.069rem;
		color: var(--background-font);

		${media.lessThan('medium')`
			word-break: break-word;
		`}
	}
	p {
		margin: 0 auto 1.6rem;
	}
	h1,
	h2,
	h3,
	h4,
	h5 {
		margin: 2.4rem auto 1rem;
	}
	ul,
	ol {
		list-style: disc;
		padding-left: 2.5rem;
		margin: 0 auto 1.6rem;
	}
	li {
		padding: 0.625rem 0;
		& > ul {
			margin-bottom: 0;
		}
	}
	p,
	li {
		code {
			word-wrap: break-word;
		}
	}
	img {
		display: block;
		max-width: 100%;
		margin: 1.875rem auto;
	}
	iframe {
		max-width: 100%;
		padding: 0 1.6rem 1.6rem;

		${media.lessThan('medium')`
			padding: 0;
		`}
	}
	blockquote {
		border-left: 0.3rem solid var(--background-auxiliar);
		padding: 0 1.875rem;
		margin: 3.125rem auto;
	}
	hr {
		border: 1px solid var(--background-auxiliar);
		margin: 3rem auto;
	}
	#twitter-widget-0,
	.instagram-media,
	.twitter-tweet {
		margin: 20px auto !important;
	}
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: 800;
		letter-spacing: 0.069rem;
		line-height: 1.4;
	}
	h1 {
		font-size: 2.8rem;
		${media.lessThan('medium')`
			font-size: 1.875rem;
		`}
	}
	h2 {
		font-size: 2.1rem;
		${media.lessThan('medium')`
			font-size: 1.375rem;
		`}
	}
	h3 {
		font-size: 1.6rem;
		${media.lessThan('medium')`
			font-size: 1.125rem;
		`}
	}
	h4 {
		font-size: 1.4rem;
	}
	h5 {
		font-size: 1.2rem;
	}
	strong {
		font-weight: 700;
	}
	.gatsby-resp-image-background-image {
		z-index: 2;
		opacity: 1 !important;
	}
	.gatsby-resp-image-image {
		box-shadow: none !important;
		transition: opacity 0.2s;
		&.lazyload {
			opacity: 0;
		}
		&.lazyloaded {
			opacity: 1;
			z-index: 3;
		}
	}
	.gatsby-highlight {
		padding: 0 1.6rem 1.6rem;
		${media.lessThan('medium')`
			padding: 0;
		`}
	}
	.instagram-media {
		margin: 1rem auto !important;
	}
	a {
		border-bottom: 1px dashed var(--background-auxiliar);
		color: var(--background-auxiliar);
		text-decoration: none;
		transition: opacity 0.5s;
		svg {
			color: var(--background-font);
		}
		&:hover {
			opacity: 0.8;
		}
	}
    pre {
        max-width: 650px;
    }
`;

export const SideContent = styled.div`
    margin-bottom: 3rem;

    ${media.greaterThan('medium')`
        margin-left: 1.5rem;
        min-width: 300px;
        width: 300px;
    `}
`;
