import styled from 'styled-components';

import { Link } from 'gatsby';
import media from 'styled-media-query';

export const ReturnWrapper = styled(Link)`
    align-items: center;
    background: none;
    color: var(--background-font);
    display: grid;
    grid-template-columns: 25px 1fr;
    margin: 0 0 0.5rem;
    text-decoration: none;
    width: fit-content;

    ${media.lessThan('medium')`
        margin: 0 0 0.5rem;
    `}
`

export const Text = styled.span`
    padding-right: 0.5rem;
`
